import React from "react";
import * as styles from "../HeaderComponent.module.scss";
import { DesktopHeaderProps } from "../DesktopHeader/DesktopHeader";
import NavLink from "../../NavLink/NavLink";
import Icons, { IconType } from "../../Icons/Icons";

const MobileHeader: React.FC<DesktopHeaderProps> = ({ menu, userName, handleLogout }) => {
    const [isOpenMenu, setOpenMenu] = React.useState(false);

    const openMenu = () => setOpenMenu(true);
    const closeMenu = () => setOpenMenu(false);

    if (!isOpenMenu) {
        return (
            <div className={styles.mobile} onClick={openMenu}>
                <div onClick={closeMenu} className={styles.mobileIcon}>
                    <Icons iconType={IconType.LIST} styles={"icon-button"} />
                </div>
                <div>{userName}</div>
                <button onClick={handleLogout} className="btn--default">
                    WYLOGUJ
                </button>
            </div>
        );
    }

    return (
        <div className={styles.mobileMenu}>
            <div className={styles.mobileMenuClose} onClick={closeMenu}>
                <Icons iconType={IconType.CLOSE} styles={"icon-button"} />
            </div>
            <ul className={styles.mobileList}>
                {menu.map((item, index) => {
                    return (
                        <li className={styles.mobileListItem} key={index}>
                            <div className={styles.mobileListWrapper}>
                                <div>{item.menuItemName}</div>
                                <ul className={styles.mobileNested}>
                                    {item?.nested?.map((nestedItem, nestedIndex) => {
                                        return (
                                            <div className={styles.mobileNestedItem} key={nestedIndex}>
                                                <NavLink linkTo={nestedItem.linkTo}>{nestedItem.menuItemName}</NavLink>
                                            </div>
                                        );
                                    })}
                                </ul>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default MobileHeader;
