import React from "react";
import * as styles from "./RecipeFormFooter.module.scss";
import { frontMessages } from "../../../utils/messages";
import { FieldType } from "../../../utils/forms.helper";

interface RecipeFormFooterProps {
    registerForm: any;
}

const RecipeFormFooter: React.FC<RecipeFormFooterProps> = ({ registerForm }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperInputs}>
                <input
                    name="company"
                    placeholder={frontMessages.placeholders.companyName}
                    type={FieldType.TEXT}
                    ref={registerForm}
                />
                <input name="hardener" placeholder={frontMessages.placeholders.hardener} type={FieldType.TEXT} />
                <input
                    name="viscosity"
                    placeholder={frontMessages.placeholders.viscosity}
                    type={FieldType.TEXT}
                    ref={registerForm}
                />
                <input
                    name="density"
                    placeholder={frontMessages.placeholders.density}
                    type={FieldType.TEXT}
                    ref={registerForm}
                />
            </div>
        </div>
    );
};

export default RecipeFormFooter;
