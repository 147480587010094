import React from "react";
import { RecipeActionType } from "../../../utils/forms.helper";

export const useRecipeFormCreatorState = () => {
    const [saveRecipeActionType, setSaveRecipeActionType] = React.useState(RecipeActionType.NONE);
    const [modalIsOpen, setModalOpen] = React.useState(false);
    const [isPricesOpen, setPricesOpen] = React.useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const setRecipeSaveActionType = (actionType: RecipeActionType) => setSaveRecipeActionType(actionType);
    const handleTogglePrices = (toggleOpenPrice: boolean) => setPricesOpen(toggleOpenPrice);

    return {
        modalIsOpen,
        saveRecipeActionType,
        isPricesOpen,
        closeModal,
        openModal,
        handleTogglePrices,
        setRecipeSaveActionType,
    };
};
