import React from "react";
import * as styles from "./App.modules.scss";
import { getAuthorizationState } from "store/auth/auth.selector";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import Recipes from "./components/Recipes/Recipes";
import MainView from "./components/MainView/MainView";
import route from "./routes";
import ReactNotification from "react-notifications-component";
import Ingredients from "components/Ingredients/Ingredients";
import RecipesHistory from "./components/RecipesHistory/RecipesHistory";
import WorkingRecipes from "./components/WorkingRecipes/WorkingRecipes";
import Users from "./components/Users/Users";
import Categories from "components/Categories/Categories";
import IngredientDetails from "components/IngredientDetails/IngredientDetails";
import { useAppLandingPage } from "./hooks/useAppLandingPage";
import CreateNewAccount from "./components/CreateNewAccount/CreateNewAccount";
import IngredientsHistory from "./components/IngredientsHistory/IngredientsHistory";
import RecipeViewWrapper from "./components/RecipeView/RecipeViewWrapper/RecipeViewWrapper";
import { HeaderComponent } from "./components/HeaderComponent/HeaderComponent";
import NewRecipeCreatorWrapper from "./components/RecipeFormCreator/NewRecipeCreatorWrapper/NewRecipeCreatorWrapper";
import SampleWrapper from "./components/SampleWrapper/SampleWrapper";
import Footer from "./components/footer/Footer";
import Project from "./components/Projects/Projects";
import { useIsInMobileView } from "./hooks/useIsInMobileView";
import WorkingRecipesHistory from "./components/WorkingRecipesHistory/WorkingRecipesHistory";
import PageNotFound from "./components/PageNotFound/PageNotFoud";
import WorkingIngredientList from "./components/WorkingIngredientList/WorkinIngredientList";
import ProjectViewWrapper from "./components/Projects/ProjectView/ProjectWiewVrapper/ProjectViewWrapper";

export const App: React.FC = () => {
    const { isAuthorized } = useSelector(getAuthorizationState);
    useAppLandingPage();
    const { isMobile } = useIsInMobileView();

    return (
        <div className={styles.App}>
            <div className={`${isAuthorized ? "contentColumn" : "content"}`}>
                <ReactNotification />
                {isAuthorized && <HeaderComponent />}
                <div className={isMobile ? "" : "container"}>
                    <div className={isMobile ? "" : "row"}>
                        <Switch>
                            <Route exact={true} path={"/"} component={MainView} />
                            <Route exact={true} path={route.USER_REGISTER} component={CreateNewAccount} />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.RECIPES}
                                component={Recipes}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.ADD_NEW_RECIPE}
                                component={NewRecipeCreatorWrapper}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.INGREDIENTS_LIST}
                                component={Ingredients}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.WORKING_INGREDIENT_LIST}
                                component={WorkingIngredientList}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.SAMPLES}
                                component={SampleWrapper}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.RECIPES_HISTORY}
                                component={RecipesHistory}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.RECIPES_HISTORY_WORKING}
                                component={WorkingRecipesHistory}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.WORKING_RECIPES}
                                component={WorkingRecipes}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.INGREDIENTS_HISTORY}
                                component={IngredientsHistory}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.CATEGORIES}
                                component={Categories}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.USERS}
                                component={Users}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.INGREDIENT_NAME}
                                component={IngredientDetails}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.WORKING_INGREDIENT_NAME}
                                component={IngredientDetails}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.RECIPE_VIEW}
                                component={RecipeViewWrapper}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.WORKING_RECIPE_VIEW}
                                component={RecipeViewWrapper}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.HISTORY_RECIPE_VIEW}
                                component={RecipeViewWrapper}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.WORKING_HISTORY_RECIPE_VIEW}
                                component={RecipeViewWrapper}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.PROJECTS}
                                component={Project}
                            />
                            <ProtectedRoute
                                isAuthorized={isAuthorized}
                                exact={true}
                                path={route.PROJECTS_VIEW}
                                component={ProjectViewWrapper}
                            />
                            <PageNotFound />
                        </Switch>
                    </div>
                </div>
                {isAuthorized && <Footer />}
            </div>
        </div>
    );
};

export default App;
