import React from "react";
import * as styles from "./AddRecipeButton.module.scss";
import { Link } from "react-router-dom";
import routes from "../../routes";
import Icons, { IconType } from "../Icons/Icons";

export enum AddRecipeSource {
    WORKING = "working",
    PRODUCTION = "production",
}

interface AddRecipeButtonProps {
    addRecipeSource: AddRecipeSource;
    recipeButtonTitle: string;
}

const AddRecipeButton: React.FC<AddRecipeButtonProps> = ({ addRecipeSource, recipeButtonTitle }) => {
    return (
        <Link
            to={{
                pathname: routes.ADD_NEW_RECIPE,
                state: {
                    addRecipeSource: addRecipeSource,
                },
            }}>
            <button className={`btn btn--default btn--height-medium ${styles.buttonStyles}`}>
                <div>{recipeButtonTitle}</div>
                <Icons iconType={IconType.PLUS} styles={"icon-button"} />
            </button>
        </Link>
    );
};

export default AddRecipeButton;
