import React from "react";
import { useForm } from "react-hook-form";
import Input from "../Input/Input";
import * as styles from "./LoginForm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { authorize } from "store/auth/auth.actions";
import { frontMessages } from "utils/messages";
import { getAuthorizationState } from "store/auth/auth.selector";
import { FieldTitle, FieldType } from "utils/forms.helper";

type FormData = {
    username: string;
    password: string;
};

export const LoginForm: React.FC = () => {
    const dispatch = useDispatch();
    const { isAuthorizing } = useSelector(getAuthorizationState);
    const { register, handleSubmit, errors, reset } = useForm<FormData>();
    const onSubmit = handleSubmit(({ username, password }) => {
        dispatch(authorize.request({ password: password, username: username }));
        reset();
    });

    return (
        <div className={styles.wrapper}>
            <form onSubmit={onSubmit} className={styles.formWrapper} autoComplete="chrome-off">
                <div className={styles.formWrapperInputs}>
                    <Input
                        name={FieldTitle.USERNAME}
                        required={true}
                        label={frontMessages.login.label}
                        register={register}
                        type={FieldType.TEXT}
                        errors={errors.username}
                        errorMessage={frontMessages.login.errorMessage}
                    />
                    <Input
                        name={FieldTitle.PASSWORD}
                        required={true}
                        label={frontMessages.password.label}
                        type={FieldType.PASSWORD}
                        register={register}
                        errorMessage={frontMessages.password.errorMessage}
                        errors={errors.password}
                    />
                </div>
                <button type="submit" className="btn btn--default" disabled={isAuthorizing}>
                    {frontMessages.loginUser}
                </button>
            </form>
        </div>
    );
};

export default LoginForm;
