import { RecipeSource } from "../RecipeView";
import { useDispatch, useSelector } from "react-redux";
import { getRecipesListState } from "../../../store/RecipesList/RecipesList.selector";
import { getRecipesHistoryState } from "../../../store/recipesHistory/recipesHistory.selector";
import { getWorkingRecipesListState } from "../../../store/workingRecipe/workingRecipe.selector";
import { getRouterState } from "../../../store/router/router.selector";
import React from "react";
import { fetchRecipesHistory, fetchWorkingRecipesHistory } from "../../../store/recipesHistory/recipesHistory.actions";
import { fetchWorkingRecipesList } from "../../../store/workingRecipe/workingRecipe.actions";

const getRecipeSourceFromUrl = (pathname: string) => {
    if (pathname.includes("historia")) {
        return RecipeSource.HISTORY_LIST;
    }

    if (pathname.includes("robocza")) {
        return RecipeSource.WORKING_LIST;
    }

    return RecipeSource.MAIN_LIST;
};

const getRecipeNumberFromUrl = (pathname: string) => {
    const lastSlash = pathname.lastIndexOf("/");

    return pathname.substring(lastSlash + 1);
};

export const useRecipeView = () => {
    const { location } = useSelector(getRouterState);
    const dispatch = useDispatch();
    const [recipeSourceState, setRecipeSourceState] = React.useState(RecipeSource.EMPTY);
    const locationType = location.state as any;
    const recipeId = locationType?.recipeId;
    const recipeSource = locationType?.recipeSource || getRecipeSourceFromUrl(location.pathname);
    const { recipesList } = useSelector(getRecipesListState);
    const { recipesHistory } = useSelector(getRecipesHistoryState);
    const { workingRecipes } = useSelector(getWorkingRecipesListState);
    const { workingRecipesHistory } = useSelector(getRecipesHistoryState);

    const setRecipeList = (() => {
        if (recipeSource === RecipeSource.MAIN_LIST) {
            return recipesList;
        }

        if (recipeSource === RecipeSource.HISTORY_LIST) {
            return recipesHistory;
        }

        if (recipeSource === RecipeSource.WORKING_HISTORY_LIST) {
            return workingRecipesHistory;
        }

        if (recipeSource === RecipeSource.WORKING_LIST) {
            return workingRecipes;
        }

        return [];
    })();

    const [selectedRecipe] = setRecipeList.filter((item) => {
        if (recipeId) {
            return item.id === recipeId;
        }

        return item.recipeNumber?.includes(getRecipeNumberFromUrl(location.pathname))
    });

    React.useEffect(() => {
        if (recipesList.length || recipesHistory.length || workingRecipes.length) {
            setRecipeSourceState(recipeSource);
        }

        // eslint-disable-next-line
    }, [recipesList, recipesHistory, workingRecipes]);

    React.useEffect(() => {
        if (!recipesHistory.length) {
            dispatch(fetchRecipesHistory.request());
        }

        if (!workingRecipes.length) {
            dispatch(fetchWorkingRecipesList.request());
        }

        if (!workingRecipesHistory.length) {
            dispatch(fetchWorkingRecipesHistory.request());
        }
        // eslint-disable-next-line
    }, []);

    return {
        recipeSourceState,
        selectedRecipe,
        recipeSource,
    };
};
