import React from "react";
import Icons, { IconType } from "../Icons/Icons";
import * as styles from "./PositionChanger.module.scss";
import { NewRecipeState } from "../RecipeFormCreator/RecipeFormCreator";

interface PositionChangerProps {
    index: number;
    inputList: NewRecipeState[];
    setInputList: any;
}

const PositionChanger: React.FC<PositionChangerProps> = ({ index, inputList, setInputList }) => {
    const moveItem = (fromIndex: number, toIndex: number) => () => {
        let ingredientList = [...inputList];
        const element = ingredientList[fromIndex];
        ingredientList.splice(fromIndex, 1);
        ingredientList.splice(toIndex, 0, element);
        setInputList(ingredientList);
    };

    const isFirst = index === 0;
    const isLast = index === inputList.length - 1;

    return (
        <>
            <div onMouseDown={moveItem(index, index + 1)}>
                <Icons iconType={IconType.ARROW} styles={`${styles.changer} ${isLast ? styles.changerDisabled : ""}`} />
            </div>
            <div onMouseDown={moveItem(index, index - 1)}>
                <Icons
                    iconType={IconType.ARROW}
                    styles={`${styles.changer} ${styles.changerUp} ${isFirst ? styles.changerDisabled : ""}`}
                />
            </div>
        </>
    );
};

export default PositionChanger;
