import { call, put } from "redux-saga/effects";
import Api from "api/recipesHistory";
import { notification } from "components/Notification/Notification";
import { fetchRecipesHistory, fetchWorkingRecipesHistory } from "store/recipesHistory/recipesHistory.actions";
import { RecipesHistoryResponse } from "api/recipesHistory";

export function* fetchRecipesHistorySaga(action: ReturnType<typeof fetchRecipesHistory.request>): Generator {
    try {
        const response = yield call(Api.fetchRecipesHistory, action.payload);
        const typedResponse = response as RecipesHistoryResponse;
        yield put(fetchRecipesHistory.success({ recipesHistory: typedResponse.recipesHistory }));
    } catch (err) {
        notification(err.message, false);
        yield put(fetchRecipesHistory.failure(err));
    }
}

export function* fetchWorkingRecipesHistorySaga(
    action: ReturnType<typeof fetchWorkingRecipesHistory.request>
): Generator {
    try {
        const response = yield call(Api.fetchWorkingRecipesHistory, action.payload);
        const typedResponse = response as RecipesHistoryResponse;
        yield put(fetchWorkingRecipesHistory.success({ recipesHistory: typedResponse.recipesHistory }));
    } catch (err) {
        notification(err.message, false);
        yield put(fetchWorkingRecipesHistory.failure(err));
    }
}
