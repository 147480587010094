import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./categories.actions"
import { CategoriesProps } from "api/categories";
import { fetchCategories } from "./categories.actions";

export type CategoriesAction = ActionType<typeof actions>;

export type CategoriesState = Readonly<{
    categories: CategoriesProps[];
    isLoading: boolean;
}>;

export const categoriesInitialState: CategoriesState = {
    isLoading: false,
    categories: [],
};

export const categoriesReducer = createReducer<CategoriesState, CategoriesAction>(categoriesInitialState)
    .handleAction(fetchCategories.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(fetchCategories.success, (state, action) => {
        return {
            ...state,
            categories: action.payload.categories,
            isLoading: false,
        };
    })
    .handleAction(fetchCategories.failure, (state: any) => {
        return {
            ...state,
            isLoading: false,
        };
    });
