import ModalComponent from "../../modal/Modal";
import * as styles from "./RecipeModal.module.scss";
import { frontMessages } from "../../../utils/messages";
import { RecipeActionType } from "../../../utils/forms.helper";
import React from "react";

interface EditRecipeModalProps {
    register: any;
    errors: any;
    modalIsOpen: boolean;
    isWorkingRecipe: boolean;
    setRecipeSaveActionType(actionType: RecipeActionType): void;
    closeModal(): void;
}

const EditRecipeModal: React.FC<EditRecipeModalProps> = ({
    modalIsOpen,
    closeModal,
    register,
    errors,
    isWorkingRecipe,
    setRecipeSaveActionType,
}) => {
    return (
        <ModalComponent modalIsOpen={modalIsOpen} closeModal={closeModal}>
            <div className={styles.wrapper}>
                <span className={styles.wrapperTitle}>Podaj powód edycji:</span>
                <div className={styles.wrapperTextArea}>
                    <textarea form="form" name="editReason" minLength={5} ref={register({ required: true })} />
                    <span className={styles.wrapperTextAreaError}>
                        {errors.editReason && frontMessages.addRecipe.form.minRequired}
                    </span>
                </div>
                <div className={styles.wrapperButtons}>
                    <button
                        className="btn btn--green"
                        type="submit"
                        form="form"
                        onMouseDown={() =>
                            isWorkingRecipe
                                ? setRecipeSaveActionType(RecipeActionType.EDIT_WORKING)
                                : setRecipeSaveActionType(RecipeActionType.EDIT)
                        }>
                        <span>{frontMessages.button.saveRecipe}</span>
                    </button>
                    <button className="btn--black" type="button" form="form" onMouseDown={closeModal}>
                        <span>{frontMessages.button.close}</span>
                    </button>
                </div>
            </div>
        </ModalComponent>
    );
};

export default EditRecipeModal;
