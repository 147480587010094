import { call, put } from "redux-saga/effects";
import Api, { WorkingRecipesListResponse } from "api/workingRecipe";
import { notification } from "components/Notification/Notification";
import { AddNewRecipeResponseProps } from "../recipeOperations/recipeOperations.actions";
import {
    addNewWorkingRecipe,
    deleteWorkingRecipe,
    editWorkingRecipe,
    fetchWorkingRecipesList,
} from "store/workingRecipe/workingRecipe.actions";
import { history } from "store/store";
import routes from "routes";
import { fetchWorkingIngredientsList } from "../workingIngredients/WorkingIngredientList.action";

export function* addNewWorkingRecipeSaga(action: ReturnType<typeof addNewWorkingRecipe.request>): Generator {
    try {
        const response = yield call(Api.addNewWorkingRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, true);
        yield put(addNewWorkingRecipe.success(typedResponse));
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchWorkingIngredientsList.request());
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(addNewWorkingRecipe.failure(response));
    }
}

export function* fetchWorkingRecipesSaga(action: ReturnType<typeof fetchWorkingRecipesList.request>): Generator {
    try {
        const response = yield call(Api.getWorkingRecipeList, action.payload);
        const typedResponse = response as WorkingRecipesListResponse;
        notification("pobrano receptury robocze", true);
        yield put(fetchWorkingRecipesList.success({ workingRecipes: typedResponse.workingRecipes }));
    } catch (response) {
        notification(response.parsedBody, false);
        yield put(fetchWorkingRecipesList.failure(response));
    }
}

export function* editWorkingRecipeSaga(action: ReturnType<typeof editWorkingRecipe.request>): Generator {
    try {
        const response = yield call(Api.editWorkingRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, typedResponse.success);
        yield put(editWorkingRecipe.success(typedResponse));
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchWorkingIngredientsList.request());
        history.push({ pathname: routes.WORKING_RECIPES });
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(editWorkingRecipe.failure(response));
    }
}

export function* deleteWorkingRecipeSaga(action: ReturnType<typeof deleteWorkingRecipe.request>): Generator {
    try {
        const response = yield call(Api.deleteWorkingRecipe, action.payload);
        const typedResponse = response as AddNewRecipeResponseProps;
        notification(typedResponse.message, typedResponse.success);
        yield put(deleteWorkingRecipe.success(typedResponse));
        yield put(fetchWorkingRecipesList.request());
        history.push({ pathname: routes.WORKING_RECIPES });
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(deleteWorkingRecipe.failure(response));
    }
}
