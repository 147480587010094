import { createAsyncAction } from "typesafe-actions";
import { AddCategoryResponse, CategoriesResponse } from "api/categories";

export const fetchCategories = createAsyncAction(
    "FETCH_CATEGORIES_REQUEST",
    "FETCH_CATEGORIES_SUCCESS",
    "FETCH_CATEGORIES_FAILURE"
)<void, CategoriesResponse, Error>();

export const addNewCategory = createAsyncAction(
    "ADD_CATEGORY_REQUEST",
    "ADD_CATEGORY_REQUEST_SUCCESS",
    "ADD_CATEGORY_FAILURE"
)<{ categoryName: string}, AddCategoryResponse, Error>();

export const editCategory = createAsyncAction(
    "EDIT_CATEGORY_REQUEST",
    "EDIT_CATEGORY_REQUEST_SUCCESS",
    "EDIT_CATEGORY_T_FAILURE"
)<{ categoryName: string; newCategoryName: string }, AddCategoryResponse, Error>();
