import { action, createAsyncAction } from "typesafe-actions";
export interface UserLogin {
    username: string;
    password: string;
}

export interface UserRegister {
    username: string;
    email: string;
    password: string;
    registerPassword: string;
    roles: string[];
    isBlocked?: boolean;
}

export interface VerifyMe {
    token: string;
}

export interface VerifyMeResponse {
    email: string;
    id: number;
    roles: string[];
    username: string;
    isBlocked: boolean;
}

export interface AuthResponse extends VerifyMeResponse {
    accessToken: string;
}

export const verifyMe = createAsyncAction("VERIFY_ME_REQUEST", "VERIFY_ME_SUCCESS", "VERIFY_ME_FAILURE")<
    VerifyMe,
    VerifyMeResponse,
    Error
>();

export const authorize = createAsyncAction("LOG_IN_USER_REQUEST", "LOG_IN_USER_SUCCESS", "LOG_IN_USER_FAILURE")<
    UserLogin,
    AuthResponse,
    Error
>();

export const createNewUser = createAsyncAction(
    "REGISTER_USER_REQUEST",
    "REGISTER_USER_SUCCESS",
    "REGISTER_USER_FAILURE"
)<UserRegister, AuthResponse, Error>();

export const logOut = () => action("LOG_OUT_USER");
