import React from "react";
import * as styles from "./hooks.module.scss";
import Icons, { IconType } from "../components/Icons/Icons";
import { useHistory, useLocation } from "react-router-dom";

export const useSearcher = () => {
    const { search } = useLocation();
    const [searchInputValue, setSearchInput] = React.useState(search.replace("?", "") || "");
    const history = useHistory();

    const handleChangeSearchInput = (phrase: string) => {
        setSearchInput(phrase);
        history.replace({ search: phrase });
    };

    const clearPhrase = () => {
        setSearchInput("");
        history.replace({ search: "" });
    };

    const searcher = (
        <div className={styles.search}>
            {searchInputValue && (
                <div className={styles.searchClearIconWrapper} onClick={clearPhrase}>
                    <Icons iconType={IconType.CLOSE} styles={styles.searchCloseIcon} />
                </div>
            )}
            <input
                type="text"
                placeholder="Szukaj..."
                value={searchInputValue}
                onChange={(e) => handleChangeSearchInput(e.target.value)}
            />
            <div className={styles.searchIconWrapper}>
                <Icons iconType={IconType.SEARCH} styles={styles.searchIcon} />
            </div>
        </div>
    );

    const searchInput = searchInputValue.toLowerCase();

    return {
        searcher,
        searchInput,
    };
};
