import { verifyMe } from "../auth/auth.actions";
import { call, put } from "redux-saga/effects";
import Api from "../../api/workingIngredientList";
import { notification } from "components/Notification/Notification";
import {
    fetchWorkingIngredientsList,
    deleteWorkingIngredient,
    updateWorkingIngredientCategory,
    updateWorkingIngredientDensity,
    updateWorkingIngredientName,
    updateWorkingIngredientPrice,
} from "./WorkingIngredientList.action";
import { fetchRecipesList } from "store/RecipesList/RecipesList.actions";
import { fetchWorkingRecipesList } from "store/workingRecipe/workingRecipe.actions";
import { fetchRecipesHistory } from "store/recipesHistory/recipesHistory.actions";
import { UpdateWorkingIngredientNameResponse, WorkingIngredientsListResponse } from "../../api/workingIngredientList";

export function* fetchWorkingIngredientsListSaga(
    action: ReturnType<typeof fetchWorkingIngredientsList.request>
): Generator {
    try {
        const response = yield call(Api.fetchWorkingIngredientsList, action.payload);
        const typedResponse = response as WorkingIngredientsListResponse;
        yield put(fetchWorkingIngredientsList.success(typedResponse));
    } catch (err) {
        notification(err.message, false);
        yield put(verifyMe.failure(err));
    }
}

export function* updateWorkingIngredientNameSaga(
    action: ReturnType<typeof updateWorkingIngredientName.request>
): Generator {
    try {
        const response = yield call(Api.updateWorkingIngredientName, action.payload);
        const typedResponse = response as UpdateWorkingIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateWorkingIngredientName.success(typedResponse));
        yield put(fetchWorkingIngredientsList.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (err) {
        notification(err.message, false);
        yield put(updateWorkingIngredientName.failure(err));
    }
}

export function* updateWorkingIngredientPriceSaga(
    action: ReturnType<typeof updateWorkingIngredientPrice.request>
): Generator {
    try {
        const response = yield call(Api.updateWorkingIngredientPrice, action.payload);
        const typedResponse = response as UpdateWorkingIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateWorkingIngredientPrice.success(typedResponse));
        yield put(fetchWorkingIngredientsList.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (err) {
        notification(err.message, false);
        yield put(updateWorkingIngredientPrice.failure(err));
    }
}

export function* updateWorkingIngredientDensitySaga(
    action: ReturnType<typeof updateWorkingIngredientDensity.request>
): Generator {
    try {
        const response = yield call(Api.updateWorkingIngredientDensity, action.payload);
        const typedResponse = response as UpdateWorkingIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateWorkingIngredientDensity.success(typedResponse));
        yield put(fetchWorkingIngredientsList.request());
        yield put(fetchRecipesList.request());
        yield put(fetchWorkingRecipesList.request());
        yield put(fetchRecipesHistory.request());
    } catch (err) {
        notification(err.message, false);
        yield put(updateWorkingIngredientDensity.failure(err));
    }
}

export function* deleteWorkingIngredientSaga(action: ReturnType<typeof deleteWorkingIngredient.request>): Generator {
    try {
        const response = yield call(Api.deleteWorkingIngredient, action.payload);
        const typedResponse = response as UpdateWorkingIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(deleteWorkingIngredient.success(typedResponse));
        yield put(fetchWorkingIngredientsList.request());
    } catch (err) {
        notification(err.message, false);
        yield put(deleteWorkingIngredient.failure(err));
    }
}

export function* updateWorkingIngredientCategorySaga(
    action: ReturnType<typeof updateWorkingIngredientCategory.request>
): Generator {
    try {
        const response = yield call(Api.updateWorkingIngredientCategory, action.payload);
        const typedResponse = response as UpdateWorkingIngredientNameResponse;
        notification(typedResponse.message, true);
        yield put(updateWorkingIngredientCategory.success(typedResponse));
        yield put(fetchWorkingIngredientsList.request());
    } catch (err) {
        notification(err.message, false);
        yield put(updateWorkingIngredientCategory.failure(err));
    }
}
