import React from "react";
import * as styles from "./RecipeViewPrintFooter.module.scss";

const RecipeViewPrintFooter: React.FC = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footerLabel}>{"2. KONTROLA JAKOŚCI"}</div>
            <div className={styles.footerItems}>
                <div>{"Sprzwdzający:"}</div>
                <div>{"Data kontroli: "}</div>
            </div>
            <div className={styles.footerItems}>
                <div>{"Lepkość:"}</div>
                <div>{"Gęstość: "}</div>
            </div>
            <div className={styles.footerItems}>
                <div>{"Połysk:"}</div>
                <div>{"Czystość: "}</div>
            </div>
            <div className={styles.footerItems}>
                <div>{"pH:"}</div>
                <div>{"Ciała stałe: "}</div>
            </div>
            <div className={styles.footerMagzine}>{"3. MAGAZYN"}</div>
            <div className={styles.footerSingleItem}>
                <div>{"Dopełniono do opakowań:"}</div>
            </div>
            <div className={styles.footerItems}>
                <div>{"Uzysk:"}</div>
                <div>{"Straty: "}</div>
            </div>
            <div className={styles.footerSingleItem}>
                <div>{"Uwagi:"}</div>
            </div>
        </div>
    );
};

export default RecipeViewPrintFooter;
