import React from "react";
import Input from "../../../Input/Input";
import { ProjectFieldTitle } from "../../projects.helper";
import { projectsMessages } from "../../projectsMessages";
import { FieldType } from "../../../../utils/forms.helper";
import ProjectButtonRecipeAdd from "../ProjectButtonRecipeAdd/ProjectButtonRecipeAdd";
import * as styles from "./ProjectCreatorDefaultInputs.module.scss";
import { ProjectProps } from "../../../../api/projects";

interface ProjectCreatorDefaultInputs {
    initialValues?: ProjectProps;
    isOpenNewRecipe: boolean;
    setOpenNewRecipe(isOpen: boolean): void;
    register: any;
    errors: any;
}

const ProjectCreatorDefaultInputs: React.FC<ProjectCreatorDefaultInputs> = ({
    register,
    errors,
    initialValues,
    isOpenNewRecipe,
    setOpenNewRecipe,
}) => {
    return (
        <div className={styles.content}>
            <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperLabel}>{projectsMessages.descriptions.solids}</div>
                <Input
                    name={ProjectFieldTitle.SOLIDS}
                    required={false}
                    label={""}
                    register={register}
                    placeholder={projectsMessages.label.solids}
                    type={FieldType.TEXT}
                    classWrapper={styles.contentWrapperInput}
                    errors={errors.solids}
                    errorMessage={projectsMessages.errors.required}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperLabel}>{projectsMessages.descriptions.gloss}</div>
                <Input
                    name={ProjectFieldTitle.GLOSS}
                    required={false}
                    label={""}
                    register={register}
                    placeholder={projectsMessages.label.gloss}
                    type={FieldType.TEXT}
                    classWrapper={styles.contentWrapperInput}
                    errors={errors.solids}
                    errorMessage={projectsMessages.errors.required}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperLabel}>{projectsMessages.descriptions.viscosity}</div>
                <Input
                    name={ProjectFieldTitle.VISCOSITY}
                    required={false}
                    label={""}
                    classWrapper={styles.contentWrapperInput}
                    register={register}
                    placeholder={projectsMessages.label.viscosity}
                    type={FieldType.TEXT}
                    errors={errors.solids}
                    errorMessage={projectsMessages.errors.required}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperLabel}>{projectsMessages.descriptions.spray}</div>
                <Input
                    name={ProjectFieldTitle.SPRAY}
                    required={false}
                    label={""}
                    register={register}
                    placeholder={projectsMessages.label.spray}
                    type={FieldType.TEXT}
                    errors={errors.solids}
                    classWrapper={styles.contentWrapperInput}
                    errorMessage={projectsMessages.errors.required}
                />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.contentWrapperLabel}>{projectsMessages.descriptions.density}</div>
                <Input
                    name={ProjectFieldTitle.DENSITY}
                    required={false}
                    label={""}
                    register={register}
                    placeholder={projectsMessages.label.density}
                    type={FieldType.TEXT}
                    errors={errors.solids}
                    classWrapper={styles.contentWrapperInput}
                    errorMessage={projectsMessages.errors.required}
                />
            </div>
            <ProjectButtonRecipeAdd
                register={register}
                initialValues={initialValues}
                isOpenNewRecipe={isOpenNewRecipe}
                setOpenNewRecipe={setOpenNewRecipe}
            />
        </div>
    );
};

export default ProjectCreatorDefaultInputs;
