import React from "react";
import { FieldTitle, FieldType, RecipeActionType } from "utils/forms.helper";
import * as styles from "./RecipeFormCreator.module.scss";
import * as ingredientStyles from "../RecipeIngredients/RecipeIngredients.module.scss";
import RecipeIngredients from "../RecipeIngredients/RecipeIngredients";
import RecipeFormFooter from "./RecipeFormFooter/RecipeFormFooter";
import routes from "routes";
import { Link } from "react-router-dom";
import Input from "components/Input/Input";
import { frontMessages } from "utils/messages";
import { SelectComponent } from "components/Select/Select";
import { RecipesProps } from "../../api/recipesList";
import EditRecipeModal from "./RecipeCreatorModals/EditRecipeModal";
import RecipeFormButtons from "./RecipeFormButtons/RecipeFormButtons";
import DeleteRecipeModal from "./RecipeCreatorModals/DeleteRecipeModal";
import { useRecipeFormCreatorState } from "./hooks/useRecipeFormCreatorState";
import { useRecipeFormCreatorStore } from "./hooks/useRecipeFormCreatorStore";
import { useRecipeCreatorForm } from "./hooks/useRecipeCreatorForm";
import { useSelector } from "react-redux";
import { getUserPrivileges } from "../../store/auth/auth.selector";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";
import { IconType } from "../Icons/Icons";

export interface NewRecipeState {
    isActive: boolean;
    ingredientName: string;
    ingredientValue: string;
    ingredientPrice: string;
    ingredientDescription: null | string;
}

interface NewRecipeProps {
    initialValues?: RecipesProps;
    isRecipeEditor: boolean;
    isRecipeCreator: boolean;
    isWorkingRecipe: boolean;
    isProjectRecipe: boolean;
    onClose?(addedRecipeName: string): void;
}

const RecipeFormCreator: React.FC<NewRecipeProps> = ({
    initialValues,
    isRecipeEditor,
    isRecipeCreator,
    isWorkingRecipe,
    onClose,
    isProjectRecipe,
}) => {
    const {
        saveRecipeActionType,
        setRecipeSaveActionType,
        modalIsOpen,
        openModal,
        closeModal,
        handleTogglePrices,
        isPricesOpen,
    } = useRecipeFormCreatorState();
    const { userName, ingredients, handleSubmitActions } = useRecipeFormCreatorStore(isProjectRecipe, onClose);
    const { errors, handleSubmit, register, setValue } = useRecipeCreatorForm(initialValues);
    const { hasPricePrivileges } = useSelector(getUserPrivileges);
    const onSubmit = () =>
        handleSubmit((values: any) => {
            const reformatValues = {
                userName: userName,
                ingredients: values.ingredients.filter((item: any) => item),
                actionType: saveRecipeActionType,
                id: initialValues ? initialValues.id : undefined,
                ...values,
            };

            handleSubmitActions(reformatValues, saveRecipeActionType);
        });

    return (
        <div className={styles.wrapperWidth}>
            {!isRecipeEditor && !isWorkingRecipe && (
                <div className={styles.wrapperTitle}>
                    {isProjectRecipe ? (
                        <button className="btn btn--black" onClick={() => onClose?.("")}>
                            {frontMessages.button.close}
                        </button>
                    ) : (
                        <Link to={routes.RECIPES}>
                            <button className="btn btn--black">{frontMessages.button.backToList}</button>
                        </Link>
                    )}
                    <div className={styles.wrapperTitleHeader}>
                        <HeaderTitleWithIcon title={"DODAJ RECEPTURĘ"} iconType={IconType.PLUS} />
                    </div>
                </div>
            )}
            <form onSubmit={onSubmit()} autoComplete="off" id="form" key={"recipeFormCreator"}>
                <div className={styles.wrapperContent}>
                    <div className={styles.wrapperContentRecipeName}>
                        <div className={styles.wrapperContentRecipeNameTitle}>
                            {frontMessages.addRecipe.form.recipeNumber}
                        </div>
                        <Input
                            name={FieldTitle.RECIPE_NUMBER}
                            required={true}
                            isRecipeInput={true}
                            label={frontMessages.addRecipe.form.recipeNumber}
                            register={register}
                            type={FieldType.TEXT}
                            errors={errors.recipeNumber}
                            pattern={{
                                value: /^([a-zA-ZÀ-Ž0-9&\-.',_\s])+$/,
                                message: "numer receptury zawiera niedozwolone znaki",
                            }}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                    <div className={styles.wrapperContentRecipeName}>
                        <div className={styles.wrapperContentRecipeNameTitle}>
                            {frontMessages.addRecipe.form.recipeName}
                        </div>
                        <Input
                            name={FieldTitle.RECIPE_NAME}
                            required={true}
                            isRecipeInput={true}
                            label={frontMessages.addRecipe.form.recipeName}
                            register={register}
                            type={FieldType.TEXT}
                            errors={errors.recipeName}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                    <div className={styles.wrapperContentRecipeName}>
                        <div>{frontMessages.addRecipe.form.category}</div>
                        <SelectComponent
                            required={true}
                            register={register}
                            setValue={setValue}
                            errors={errors.category}
                            initialSelectValue={initialValues?.category}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                </div>
                <div className={styles.wrapperIngredientsTable}>
                    <div className={styles.wrapperIngredientsTableTitle}>
                        <span className={ingredientStyles.formEmptyPositionChanger}> </span>
                        <span className={ingredientStyles.formIndex}>lp.</span>
                        <span className={ingredientStyles.formName}>Składnik</span>
                        <span className={ingredientStyles.formValue}>Skład %</span>
                        <span className={`${ingredientStyles.formSum} ${styles.wrapperIngredientsTableHeader}`}>
                            Skład wag
                        </span>
                        <span className={ingredientStyles.formDensity}>Skład obj</span>
                        <div className={ingredientStyles.formPrice}>
                            {hasPricePrivileges && (
                                <button
                                    onClick={() => handleTogglePrices(!isPricesOpen)}
                                    type="button"
                                    className={`btn--width-small btn--height-medium ${
                                        isPricesOpen ? "btn--black" : "btn--default"
                                    }`}>
                                    Cena $
                                </button>
                            )}
                        </div>
                        <span className={ingredientStyles.formButton} />
                    </div>
                    <div>
                        <RecipeIngredients
                            registerForm={register}
                            errors={errors}
                            isPricesOpen={isPricesOpen}
                            ingredients={ingredients}
                            ingredientInitialValues={initialValues?.ingredients}
                            initialSumValue={initialValues?.recipeSum}
                        />
                    </div>
                </div>
                <div className={styles.wrapperTextarea}>
                    <span className={styles.wrapperTextareaLabel}>Dodatkowe uwagi:</span>
                    <textarea
                        name={FieldTitle.DEFAULT_VALUE}
                        id={FieldTitle.DEFAULT_VALUE}
                        required={false}
                        ref={register({})}
                    />
                </div>
                <RecipeFormFooter registerForm={register} />
                <RecipeFormButtons
                    setRecipeSaveActionType={setRecipeSaveActionType}
                    openModal={openModal}
                    isWorkingRecipe={isWorkingRecipe}
                    isRecipeCreator={isRecipeCreator}
                    isRecipeEditor={isRecipeEditor}
                />
            </form>
            {(saveRecipeActionType === RecipeActionType.EDIT ||
                saveRecipeActionType === RecipeActionType.EDIT_WORKING) && (
                <EditRecipeModal
                    closeModal={closeModal}
                    isWorkingRecipe={isWorkingRecipe}
                    modalIsOpen={modalIsOpen}
                    errors={errors}
                    register={register}
                    setRecipeSaveActionType={setRecipeSaveActionType}
                />
            )}
            {(saveRecipeActionType === RecipeActionType.DELETE ||
                saveRecipeActionType === RecipeActionType.DELETE_WORKING) && (
                <DeleteRecipeModal
                    closeModal={closeModal}
                    modalIsOpen={modalIsOpen}
                    errors={errors}
                    isWorkingRecipe={isWorkingRecipe}
                    register={register}
                    setRecipeSaveActionType={setRecipeSaveActionType}
                />
            )}
        </div>
    );
};

export default RecipeFormCreator;
