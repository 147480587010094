import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationState } from "../../../store/auth/auth.selector";
import { getIngredientsListState } from "../../../store/IngredientList/IngredientsList.selector";
import React from "react";
import { fetchIngredientsList } from "../../../store/IngredientList/ingredienstList.action";
import { addNewRecipe, deleteRecipe, editRecipe } from "../../../store/recipeOperations/recipeOperations.actions";
import {
    addNewWorkingRecipe,
    deleteWorkingRecipe,
    editWorkingRecipe,
} from "../../../store/workingRecipe/workingRecipe.actions";
import { RecipeActionType } from "../../../utils/forms.helper";
import { history } from "../../../store/store";
import routes from "../../../routes";

export const useRecipeFormCreatorStore = (isProjectRecipe: boolean, onClose?: (addedRecipeName: string) => void) => {
    const dispatch = useDispatch();
    const { userName } = useSelector(getAuthorizationState);
    const { ingredients } = useSelector(getIngredientsListState);
    const addNewRecipeRequest = (reformatValues: any) => dispatch(addNewRecipe.request(reformatValues));
    const addNewWorkingRecipeRequest = (reformatValues: any) => dispatch(addNewWorkingRecipe.request(reformatValues));
    const editRecipeRequest = (reformatValues: any) => dispatch(editRecipe.request(reformatValues));
    const editWorkingRecipeRequest = (reformatValues: any) => dispatch(editWorkingRecipe.request(reformatValues));
    const deleteRecipeRequest = (reformatValues: any) => dispatch(deleteRecipe.request(reformatValues));
    const deleteWorkingRecipeRequest = (reformatValues: any) => dispatch(deleteWorkingRecipe.request(reformatValues));
    const handleSubmitActions = (reformatValues: any, saveRecipeActionType: RecipeActionType) => {
        const actionStrategy = {
            [RecipeActionType.CREATE]: () => addNewRecipeRequest(reformatValues),
            [RecipeActionType.CREATE_WORKING]: () => {
                addNewWorkingRecipeRequest(reformatValues);
                if (isProjectRecipe) {
                    onClose?.(reformatValues.recipeName);
                } else {
                    history.push({ pathname: routes.WORKING_RECIPES });
                }
            },
            [RecipeActionType.EDIT]: () => editRecipeRequest(reformatValues),
            [RecipeActionType.DELETE]: () => deleteRecipeRequest(reformatValues),
            [RecipeActionType.EDIT_WORKING]: () => editWorkingRecipeRequest(reformatValues),
            [RecipeActionType.DELETE_WORKING]: () => deleteWorkingRecipeRequest(reformatValues),
        };

        // @ts-ignore
        if (actionStrategy[saveRecipeActionType]) {
            // @ts-ignore
            return actionStrategy[saveRecipeActionType]();
        }

        return;
    };

    React.useEffect(() => {
        const fetchIngredients = () => dispatch(fetchIngredientsList.request());

        fetchIngredients();
    }, [dispatch]);

    return {
        userName,
        ingredients,
        handleSubmitActions,
    };
};
