import React from "react";
import Modal from "react-modal";
import * as styles from "./Modal.module.scss";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#e4e1e1",
        minHeight: "300px",
        minWidth: "600px",
        padding: "5px",
        overflow: "hidden",
    },
};

Modal.setAppElement("#root");

interface ModalComponentProps {
    modalIsOpen: boolean;
    closeModal(): void;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ children, modalIsOpen, closeModal }) => {
    return (
        <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Example Modal" overlayClassName={styles.overlay}>
            <div className={styles.wrapper} onClick={closeModal}>
                <div className={styles.close} />
            </div>
            <div className={styles.children}>{children}</div>
        </Modal>
    );
};

export default ModalComponent;
