import React from "react";
import RecipeView, { RecipeSource } from "../RecipeView";
import RecipeEditor from "../../RecipeEditor/RecipeEditor";
import Stepper from "../../Stepper/Stepper";
import Step from "../../Step/Step";
import { useRecipeView } from "../hooks/useRecipeView";
import * as styles from "./RecipeViewWrapper.module.scss";
import { frontMessages } from "../../../utils/messages";
import { useHistory } from "react-router-dom";
import { IconType } from "../../Icons/Icons";
import HeaderTitleWithIcon from "../../HeaderTitleWithIcon/HeaderTitleWithIcon";

const RecipeViewWrapper: React.FC = () => {
    const { selectedRecipe, recipeSource } = useRecipeView();
    const { goBack } = useHistory();

    return (
        <>
            <Stepper>
                <Step
                    render={(props) => {
                        return (
                            <>
                                <div className={styles.header}>
                                    <button className="btn btn--black" onClick={goBack}>
                                        {frontMessages.button.backToList}
                                    </button>
                                    <div className={styles.headerTitle}>
                                        <HeaderTitleWithIcon title={"PODGLĄD"} iconType={IconType.VIEW} />
                                    </div>
                                </div>
                                <RecipeView
                                    onNextStep={props.onNextStep}
                                    selectedRecipe={selectedRecipe}
                                    recipeSource={recipeSource}
                                />
                            </>
                        );
                    }}
                />
                <Step
                    render={(props) => {
                        return (
                            <RecipeEditor
                                selectedRecipe={selectedRecipe}
                                onPrevStep={props.onPreviousStep}
                                isWorkingRecipe={recipeSource === RecipeSource.WORKING_LIST}
                            />
                        );
                    }}
                />
            </Stepper>
        </>
    );
};

export default RecipeViewWrapper;
