import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/categories/categories.selector";
import AddNewCategory from "components/AddNewCategory/AddNewCategory";
import * as styles from "./Categories.module.scss";
import { useForm } from "react-hook-form";
import { editCategory } from "store/categories/categories.actions";
import { FieldTitle, FieldType } from "utils/forms.helper";
import { frontMessages } from "utils/messages";
import { useSearcher } from "../../hooks/useSearcher";
import { usePagination } from "../../hooks/usePagination";
import { useSorting } from "../../hooks/useSorting";
import Icons, { IconType } from "../Icons/Icons";
import { sortByDate, sortByName } from "../../services/filter.services";
import { getUserPrivileges } from "../../store/auth/auth.selector";
import HeaderTitleWithIcon from "../HeaderTitleWithIcon/HeaderTitleWithIcon";

export interface ClickedCategoriesState {
    categoryName: string;
    categoryId: number;
}

export const ClickedCategoriesInitialState = {
    categoryName: "",
    categoryId: 0,
};

const Categories: React.FC = () => {
    const { categories } = useSelector(getCategories);
    const [isEdit, setEdit] = React.useState(false);
    const { hasEditorPrivileges } = useSelector(getUserPrivileges);
    const [clickedCategory, setClickedCategory] = React.useState<ClickedCategoriesState>(ClickedCategoriesInitialState);
    const { handleSubmit, register } = useForm<FormData>();
    const { handleSortBy, sortBy } = useSorting(true);
    const dispatch = useDispatch();
    const handleCloseEditForm = () => setEdit(false);
    const onSubmit = () =>
        handleSubmit((values: any) => {
            dispatch(
                editCategory.request({
                    categoryName: clickedCategory.categoryName,
                    newCategoryName: values.newCategoryName,
                })
            );
            handleCloseEditForm();
        });
    const { searcher, searchInput } = useSearcher();
    const { paginationComponent, offset, PER_PAGE } = usePagination(categories.length);
    const handleClick = (categoryName: string, categoryId: number) => () => {
        setEdit(true);
        setClickedCategory({
            categoryName: categoryName,
            categoryId: categoryId,
        });
    };

    return (
        <div className={styles.categories}>
            <div className={styles.categoriesNewCategory}>{hasEditorPrivileges && <AddNewCategory />}</div>
            <div className={styles.categoriesContent}>
                <div className={styles.categoriesWrapper}>
                    <div className={styles.categoriesTitle}>
                        <HeaderTitleWithIcon title={"KATEGORIE"} iconType={IconType.CATEGORY} />
                        <div className={styles.categoriesCounter}>
                            <span>Ilość kategorii: </span>
                            <span className="bold">{categories.length}</span>
                        </div>
                        <div>{searcher}</div>
                    </div>
                    <div className={styles.categoriesHeader}>
                        <span className={styles.categoriesIndex}>lp.</span>
                        <span className={styles.categoriesName} onClick={handleSortBy}>
                            Nazwa kategorii
                            <Icons iconType={IconType.SORT} styles={styles.categoriesNameIcon} />
                        </span>
                    </div>
                    <ul>
                        {categories
                            .filter((item) => item.categoryName.toLowerCase().includes(searchInput))
                            .sort((first, second) => sortByName(first.categoryName, second.categoryName, sortBy))
                            .sort((first, second) => sortByDate(first.categoryName, second.categoryName, sortBy))
                            .slice(offset, offset + PER_PAGE)
                            .map((item, index) => {
                                return (
                                    <li className={styles.categoriesListItemWrapper} key={index}>
                                        <span className={styles.categoriesIndex}>{index + offset + 1 + ". "}</span>
                                        <span className={styles.categoriesName}>{item.categoryName}</span>
                                        {hasEditorPrivileges && (
                                            <button
                                                onClick={handleClick(item.categoryName, item.categoryId)}
                                                disabled={isEdit}>
                                                <span>{frontMessages.button.edit}</span>
                                                <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                                            </button>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {isEdit && (
                    <div className={styles.categoriesEdit}>
                        <span className={styles.categoriesEditName}> {clickedCategory.categoryName} </span>
                        <form onSubmit={onSubmit()} className={styles.categoriesEditForm}>
                            <span className={styles.categoriesEditDescription}>Zmień nazwę kategorii</span>
                            <input
                                ref={register}
                                type={FieldType.TEXT}
                                placeholder={frontMessages.placeholders.newCategoryName}
                                name={FieldTitle.NEW_CATEGORY_NAME}
                            />
                            <button
                                className={`${styles.categoriesEditSubmit} btn--default btn--width-medium btn--green`}
                                type="submit">
                                {frontMessages.button.save}
                            </button>
                            <button
                                onClick={handleCloseEditForm}
                                type="button"
                                className="btn--black btn--width-medium">
                                {frontMessages.button.cancel}
                            </button>
                        </form>
                    </div>
                )}
            </div>
            {paginationComponent}
        </div>
    );
};

export default Categories;
