import { createAsyncAction } from "typesafe-actions";
import {
    DeleteIngredientRequest,
    IngredientsListResponse, UpdateIngredientCategoryRequest,
    UpdateIngredientDensityRequest,
    UpdateIngredientNameRequest,
    UpdateIngredientNameResponse,
    UpdateIngredientPriceRequest,
} from "api/ingredientsList";

export const fetchIngredientsList = createAsyncAction(
    "FETCH_INGREDIENT_LIST_REQUEST",
    "FETCH_INGREDIENT_LIST_SUCCESS",
    "FETCH_INGREDIENT_LIST_FAILURE"
)<void, IngredientsListResponse, Error>();

export const updateIngredientName = createAsyncAction(
    "UPDATE_INGREDIENT_NAME_REQUEST",
    "UPDATE_INGREDIENT_NAME_SUCCESS",
    "UPDATE_INGREDIENT_NAME_FAILURE"
)<UpdateIngredientNameRequest, UpdateIngredientNameResponse, Error>();

export const updateIngredientCategory = createAsyncAction(
    "UPDATE_INGREDIENT_CATEGORY_REQUEST",
    "UPDATE_INGREDIENT_CATEGORY_SUCCESS",
    "UPDATE_INGREDIENT_CATEGORY_FAILURE"
)<UpdateIngredientCategoryRequest, UpdateIngredientNameResponse, Error>();

export const updateIngredientPrice = createAsyncAction(
    "UPDATE_INGREDIENT_PRICE_REQUEST",
    "UPDATE_INGREDIENT_PRICE_SUCCESS",
    "UPDATE_INGREDIENT_PRICE_FAILURE"
)<UpdateIngredientPriceRequest, UpdateIngredientNameResponse, Error>();

export const updateIngredientDensity = createAsyncAction(
    "UPDATE_INGREDIENT_DENSITY_REQUEST",
    "UPDATE_INGREDIENT_DENSITY_SUCCESS",
    "UPDATE_INGREDIENT_DENSITY_FAILURE"
)<UpdateIngredientDensityRequest, UpdateIngredientNameResponse, Error>();

export const deleteIngredient = createAsyncAction(
    "DELETE_INGREDIENT_REQUEST",
    "DELETE_INGREDIENT_SUCCESS",
    "DELETE_INGREDIENT_FAILURE"
)<DeleteIngredientRequest, UpdateIngredientNameResponse, Error>();
