import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { authorizationReducer, AuthorizationState } from "./auth/auth.reducer";
import { ingredientsListReducer, IngredientsListState } from "./IngredientList/IngredientsListreducer";
import {
    ingredientsHistoryReducer,
    IngredientsHistoryState,
} from "store/ingredientsHistory/IngredientsHistory.reducer";
import { recipesListReducer, RecipesListState } from "store/RecipesList/RecipesList.reducer";
import { recipesHistoryReducer, RecipesHistoryState } from "store/recipesHistory/recipesHistory.reducer";
import { categoriesReducer, CategoriesState } from "store/categories/categories.reducer";
import { workingRecipesListReducer, WorkingRecipesListState } from "store/workingRecipe/workingRecipe.reducer";
import { userListReducer, UserListState } from "store/userManagement/userManagement.reducer";
import { sampleListReducer, SampleListState } from "./sampleList/sampleList.reducer";
import { projectListReducer, ProjectListState } from "./projects/projects.reducer";
import {
    workingIngredientsListReducer,
    WorkingIngredientsListState,
} from "./workingIngredients/WorkingIngredientList.reducer";

export interface AppState {
    router: History<History.PoorMansUnknown>;
    auth: AuthorizationState;
    ingredientsList: IngredientsListState;
    workingIngredientsList: WorkingIngredientsListState;
    ingredientsHistory: IngredientsHistoryState;
    recipesList: RecipesListState;
    recipesHistory: RecipesHistoryState;
    categories: CategoriesState;
    workingRecipesList: WorkingRecipesListState;
    userList: UserListState;
    sampleList: SampleListState;
    projectsList: ProjectListState;
}

const createRootReducer = (history: History<History.PoorMansUnknown>) =>
    combineReducers<AppState>({
        router: connectRouter(history) as any, // needs different interface?
        auth: authorizationReducer,
        ingredientsList: ingredientsListReducer,
        workingIngredientsList: workingIngredientsListReducer,
        ingredientsHistory: ingredientsHistoryReducer,
        recipesList: recipesListReducer,
        recipesHistory: recipesHistoryReducer,
        categories: categoriesReducer,
        workingRecipesList: workingRecipesListReducer,
        userList: userListReducer,
        sampleList: sampleListReducer,
        projectsList: projectListReducer,
    });

export default createRootReducer;
