import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserListState } from "store/userManagement/userManagement.selector";
import { fetchUserList } from "store/userManagement/userManagement.actions";
import UserList from "components/UserList/UserList";
import CreateNewAccount from "../CreateNewAccount/CreateNewAccount";
import * as styles from "./Users.module.scss";
import { frontMessages } from "../../utils/messages";
import { getUserPrivileges } from "../../store/auth/auth.selector";
import { Redirect } from "react-router";
import Icons, { IconType } from "../Icons/Icons";
import EditUserPrivileges from "./EditUserPrivilages/EditUserPrivileges";

const Users: React.FC = () => {
    const dispatch = useDispatch();
    const { users } = useSelector(getUserListState);
    const { hasOwnerPrivileges } = useSelector(getUserPrivileges);
    const [showForm, setShowForm] = React.useState(false);
    const [editForm, setEditForm] = React.useState(false);
    const [userId, setUserId] = React.useState(0);
    const selectedUser = users.find((item) => item.id === userId);
    const handleCreateNewUser = () => setShowForm(true);
    const handleEditUser = (id: number) => () => {
        setUserId(id);
        setEditForm(false);
        setEditForm(true);
    };
    const handleClose = () => setShowForm(false);
    const handleCloseEditForm = () => {
        setUserId(0);
        setEditForm(false);
    };

    React.useEffect(() => {
        if (!users.length && hasOwnerPrivileges) {
            dispatch(fetchUserList.request());
        }
    }, [dispatch, users, hasOwnerPrivileges]);

    if (!hasOwnerPrivileges) {
        return <Redirect to={"/"} />;
    }

    return (
        <div className={styles.users}>
            <div className={styles.usersList}>
                <UserList users={users} handleEditUser={handleEditUser} />
            </div>
            <div className={styles.usersNew}>
                {!editForm && (
                    <>
                        {showForm ? (
                            <CreateNewAccount handleClose={handleClose} />
                        ) : (
                            <button
                                onClick={handleCreateNewUser}
                                className={"btn btn--default btn--width-large"}
                                type="button">
                                {frontMessages.button.addUser}
                                <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                            </button>
                        )}
                    </>
                )}
                {editForm && (
                    <EditUserPrivileges handleCloseEditForm={handleCloseEditForm} selectedUser={selectedUser} />
                )}
            </div>
        </div>
    );
};

export default Users;
