import React from "react";
import Stepper from "../Stepper/Stepper";
import Step from "../Step/Step";
import * as styles from "./SampleWrapper.module.scss";
import SampleFormCreator from "./SampleFormCreator/SampleFormCreator";
import SampleList from "./SampleList/SampleList";
import { fetchSampleList } from "../../store/sampleList/sampleList.actions";
import { useDispatch, useSelector } from "react-redux";
import { frontMessages } from "../../utils/messages";
import { SampleProps } from "../../api/sampleList";
import Icons, { IconType } from "../Icons/Icons";
import { getSampleListState } from "../../store/sampleList/sampleList.selector";

interface SamplesStateData {
    sampleName: string;
    userName: string;
    supplier: string;
    description: string;
    placement: string;
    createdAt: string;
    id: number;
}

export const sampleInitialState = {
    sampleName: "",
    userName: "",
    supplier: "",
    description: "",
    placement: "",
    createdAt: "",
    id: 0,
};

const SampleWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const [sampleDetails, setSampleDetails] = React.useState<SamplesStateData>(sampleInitialState);
    const handleSampleOnClick = (details: SampleProps) => setSampleDetails(details);
    const handleCloseSampleView = () => setSampleDetails({ ...sampleInitialState });
    const { sampleList } = useSelector(getSampleListState);
    React.useEffect(() => {
        if (!sampleList?.length) {
            dispatch(fetchSampleList.request());
        }
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Stepper>
                <Step
                    render={(props) => {
                        return (
                            <div className={styles.wrapper}>
                                <button className={"btn btn--default btn--width-medium"} onClick={props.onNextStep}>
                                    {frontMessages.button.addSample}
                                    <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                                </button>
                                <SampleList handleSampleOnClick={handleSampleOnClick} onNextStep={props.onNextStep} />
                            </div>
                        );
                    }}
                />
                <Step
                    render={(props) => {
                        const handlePreviousStep = () => {
                            handleCloseSampleView();
                            props.onPreviousStep?.();
                        };
                        return <SampleFormCreator onPreviousStep={handlePreviousStep} sampleDetails={sampleDetails} />;
                    }}
                />
            </Stepper>
        </>
    );
};

export default SampleWrapper;
