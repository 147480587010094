import * as styles from "../ProjectCreator.module.scss";
import Input from "../../../Input/Input";
import { ProjectFieldTitle } from "../../projects.helper";
import { projectsMessages } from "../../projectsMessages";
import { FieldType } from "../../../../utils/forms.helper";
import React from "react";
import { FieldErrors } from "react-hook-form";
import { ProjectCreatorFormData } from "../ProjectCreator";

interface ProjectCreatorInputsProps {
    errors: FieldErrors<ProjectCreatorFormData>;
    selectComponent: JSX.Element;
    register: any;
}

const ProjectCreatorInputs: React.FC<ProjectCreatorInputsProps> = ({ register, errors, selectComponent }) => {
    return (
        <>
            <div className={styles.descNum}>
                <div className={styles.descNumWrapper}>
                    <div className={styles.descNumWrapperHeader}>Numer Produkcyjny</div>
                    <div className={styles.descNumWrapperInputs}>
                        <Input
                            name={ProjectFieldTitle.PRODUCTION_NUMBER}
                            required={true}
                            label={""}
                            register={register}
                            placeholder={projectsMessages.label.productionNumber}
                            type={FieldType.TEXT}
                            errors={errors.productionNumber}
                            errorMessage={projectsMessages.errors.required}
                        />
                    </div>
                </div>
                <div className={styles.descNumWrapper}>
                    <div className={styles.descNumWrapperHeader}>Produkt konkurencji/do wykonania</div>
                    <div className={styles.descNumWrapperInputs}>
                        <Input
                            name={ProjectFieldTitle.TO_WORK}
                            required={true}
                            label={""}
                            register={register}
                            placeholder={projectsMessages.label.toWork}
                            type={FieldType.TEXT}
                            errors={errors.toWork}
                            errorMessage={projectsMessages.errors.required}
                        />
                    </div>
                </div>
                <div className={styles.descNumWrapper}>
                    <div className={styles.descNumWrapperHeader}>Numer wewnętrzny</div>
                    <div className={styles.descNumWrapperInputs}>
                        <Input
                            name={ProjectFieldTitle.WORKING_NUMBER}
                            required={true}
                            label={""}
                            register={register}
                            placeholder={projectsMessages.label.workingNumber}
                            type={FieldType.TEXT}
                            errors={errors.workingNumber}
                            errorMessage={projectsMessages.errors.required}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.descProps}>
                <div className={styles.descPropsWrapper}>
                    <div className={styles.descPropsWrapperHeader}>Uwagi / Cechy:</div>
                    <div className={styles.descPropsWrapperTextarea}>
                        <Input
                            name={ProjectFieldTitle.PROPERTIES}
                            required={true}
                            label={""}
                            register={register}
                            placeholder={projectsMessages.label.properties}
                            type={FieldType.TEXT_AREA}
                            errors={errors.properties}
                            errorMessage={projectsMessages.errors.required}
                        />
                    </div>
                </div>
                <div className={styles.descPropsWrapper}>
                    <div className={styles.descPropsWrapperHeader}>Skład / Surowce:</div>
                    <div className={styles.descPropsWrapperTextarea}>
                        <Input
                            name={ProjectFieldTitle.RESOURCES}
                            required={true}
                            label={""}
                            register={register}
                            placeholder={projectsMessages.label.resources}
                            type={FieldType.TEXT_AREA}
                            errors={errors.resources}
                            errorMessage={projectsMessages.errors.required}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.descSelects}>
                <div className={styles.descSelectsWrapper}>
                    <div className={styles.descSelectsWrapperHeader}>STATUS:</div>
                    <div className={styles.descSelectsWrapperSelect}>{selectComponent}</div>
                </div>
                <div className={styles.descSelectsWrapper}>
                    <div className={styles.descSelectsWrapperHeader}>TERMIN REALIZACJI:</div>
                    <div className={`${styles.descSelectsWrapperSelect} ${styles.descSelectsWrapperDate}`}>
                        <input type="date" ref={register} name={ProjectFieldTitle.REALIZATION_DATE} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectCreatorInputs;
