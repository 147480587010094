import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { createNewUser } from "../../store/auth/auth.actions";
import Input from "../Input/Input";
import { FieldTitle, FieldType } from "../../utils/forms.helper";
import { frontMessages } from "../../utils/messages";
import * as styles from "./CreateNewAccount.module.scss";

type FormData = {
    username: string;
    email: string;
    password: string;
    registerPassword: string;
    viewer: string;
    editor: string;
    owner: string;
    price: string;
};

interface CreateNewAccountProps {
    handleClose(): void;
}

export const CreateNewAccount: React.FC<CreateNewAccountProps> = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, reset } = useForm<FormData>();
    const onSubmit = handleSubmit(({ username, password, email, registerPassword, viewer, editor, owner, price }) => {
        const roles = [
            viewer ? "viewer" : "",
            editor ? "editor" : "",
            owner ? "owner" : "",
            price ? "price" : "",
        ].filter((item) => item.length);
        dispatch(createNewUser.request({ username, email, password, registerPassword, roles }));
        reset();
    });

    return (
        <div className={styles.wrapper}>
            <form onSubmit={onSubmit} className={styles.formWrapper} autoComplete="off">
                <Input
                    name={FieldTitle.USERNAME}
                    required={true}
                    label={frontMessages.login.label}
                    register={register}
                    type={FieldType.TEXT}
                    errors={errors.username}
                    errorMessage={frontMessages.login.errorMessage}
                />
                <Input
                    name={FieldTitle.EMAIL}
                    required={true}
                    label={frontMessages.email.label}
                    type={FieldType.TEXT}
                    register={register}
                    errorMessage={frontMessages.email.errorMessage}
                    errors={errors.email}
                />
                <Input
                    name={FieldTitle.PASSWORD}
                    required={true}
                    label={frontMessages.password.label}
                    type={FieldType.PASSWORD}
                    register={register}
                    pattern={{
                        value: /^(?=.*[A-ZÀ-ŽА-ЪЬЮ-ЯA-я]).{8,}$/,
                        message: "hasło musi zawierać 8 znaków i jedną wielką literę",
                    }}
                    errorMessage={frontMessages.password.errorMessage}
                    errors={errors.password}
                />
                <Input
                    name={FieldTitle.REGISTER_PASSWORD}
                    required={true}
                    label={frontMessages.registerPassword.label}
                    type={FieldType.PASSWORD}
                    register={register}
                    errorMessage={frontMessages.registerPassword.errorMessage}
                    errors={errors.registerPassword}
                />
                <Input
                    name={FieldTitle.VIEWER_PRIVILEGE}
                    required={true}
                    label={frontMessages.privileges.viewer}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.privileges.privilegesError}
                    errors={errors.viewer}
                />
                <Input
                    name={FieldTitle.EDITOR_PRIVILEGE}
                    required={false}
                    label={frontMessages.privileges.editor}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.registerPassword.errorMessage}
                    errors={errors.editor}
                />
                <Input
                    name={FieldTitle.PRICE_PRIVILEGE}
                    required={false}
                    label={frontMessages.privileges.price}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.privileges.privilegesError}
                    errors={errors.price}
                />
                <Input
                    name={FieldTitle.OWNER_PRIVILEGE}
                    required={false}
                    label={frontMessages.privileges.owner}
                    type={FieldType.CHECKBOX}
                    register={register}
                    errorMessage={frontMessages.registerPassword.errorMessage}
                    errors={errors.owner}
                />
                <button className={`btn btn--green ${styles.wrapperButton}`} type="submit">
                    {frontMessages.createNewAccount}
                </button>
            </form>
            <button className={`btn--black ${styles.wrapperButton}`} onClick={handleClose} type="button">
                {frontMessages.button.close}
            </button>
        </div>
    );
};

export default CreateNewAccount;
