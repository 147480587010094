import { call, put } from "redux-saga/effects";
import Api from "api/userManagement";
import { notification } from "components/Notification/Notification";
import { editUser, fetchUserList } from "store/userManagement/userManagement.actions";
import { UserManagementResponse } from "api/userManagement";

export function* fetchUserListSaga(action: ReturnType<typeof fetchUserList.request>): Generator {
    try {
        const response = yield call(Api.fetchUserList, action.payload);
        const typedResponse = response as UserManagementResponse;
        yield put(fetchUserList.success({ users: typedResponse.users }));
    } catch (err) {
        notification(err.message, false);
        yield put(fetchUserList.failure(err));
    }
}

export function* editUserSaga(action: ReturnType<typeof editUser.request>): Generator {
    try {
        const response = yield call(Api.editUser, action.payload);
        const typedResponse = response as any;
        yield put(editUser.success({ success: typedResponse?.success, message: "Zmieniono uprawnienia" }));
        notification("Zmieniono uprawnienia", true);
        yield put(fetchUserList.request());
    } catch (err) {
        notification(err.message, false);
        yield put(editUser.failure(err));
    }
}
