import { call, put } from "redux-saga/effects";
import Api from "../../api/sampleList";
import { notification } from "../../components/Notification/Notification";
import { addNewSample, deleteSample, editSample, fetchSampleList } from "./sampleList.actions";
import { ApiResponse } from "../recipeOperations/recipeOperations.actions";

export function* addNewSampleSaga(action: ReturnType<typeof addNewSample.request>): Generator {
    try {
        const response = yield call(Api.addNewSample, action.payload);
        const typedResponse = response as ApiResponse;
        notification(typedResponse.message, typedResponse.success);
        yield put(addNewSample.success(typedResponse));
        yield put(fetchSampleList.request());
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(addNewSample.failure(response));
    }
}

export function* editSampleSaga(action: ReturnType<typeof editSample.request>): Generator {
    try {
        const response = yield call(Api.editSample, action.payload);
        const typedResponse = response as ApiResponse;
        notification(typedResponse.message, typedResponse.success);
        yield put(editSample.success());
        yield put(fetchSampleList.request());
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(editSample.failure(response));
    }
}

export function* deleteSampleSaga(action: ReturnType<typeof deleteSample.request>): Generator {
    try {
        const response = yield call(Api.deleteSample, action.payload);
        const typedResponse = response as ApiResponse;
        notification(typedResponse.message, typedResponse.success);
        yield put(deleteSample.success());
        yield put(fetchSampleList.request());
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(editSample.failure(response));
    }
}

export function* fetchSamplesSaga(action: ReturnType<typeof fetchSampleList.request>): Generator {
    try {
        const response = yield call(Api.getSampleList, action.payload);
        const typedResponse = response as any;
        notification(typedResponse.message, typedResponse.success);
        yield put(fetchSampleList.success(typedResponse));
    } catch (response) {
        notification(response.parsedBody.message, false);
        yield put(addNewSample.failure(response));
    }
}
