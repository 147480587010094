import React from "react";
import { useForm } from "react-hook-form";

type FormData = {
    recipeNumber: string;
    recipeName: string;
    category: string;
    recipeSum: string;
    editReason: string;
    deleteReason: string;
    defaultValue: string;
};

export const useRecipeCreatorForm = (initialValues: any) => {
    const { handleSubmit, register, errors, setValue } = useForm<FormData>({ mode: "onBlur" });
    React.useEffect(() => {
        if (initialValues) {
            const initialValuesMap = Object.entries(initialValues);
            initialValuesMap.map((item) => {
                return setValue(item[0], item[1]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        handleSubmit,
        setValue,
        register,
        errors,
    };
};
