import React from "react";
import * as styles from "../../../SampleWrapper/SampleWrapper.module.scss";
import Stepper from "../../../Stepper/Stepper";
import Step from "../../../Step/Step";
import ProjectCreator from "../../ProjectCreator/ProjectCreator";
import { useProjectView } from "../useProjectView";
import ProjectView from "../ProjectView";

export const ProjectViewWrapper: React.FC = () => {
    const { project } = useProjectView();

    return (
        <div className={styles.wrapper}>
            <Stepper>
                <Step
                    step={1}
                    render={(props) => {
                        return <ProjectView project={project} goToEditView={() => props.navigateTo?.(2)} />;
                    }}
                />
                <Step
                    step={2}
                    render={(props) => {
                        return (
                            <ProjectCreator
                                backToList={() => props.navigateTo?.(1)}
                                initialValues={project}
                                backToProjectView={() => props.navigateTo?.(1)}
                                isProjectEditor={true}
                            />
                        );
                    }}
                />
            </Stepper>
        </div>
    );
};

export default ProjectViewWrapper;
