import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationState } from "../../../store/auth/auth.selector";
import { addNewSample, deleteSample, editSample } from "../../../store/sampleList/sampleList.actions";
import { frontMessages } from "../../../utils/messages";
import Input from "../../Input/Input";
import * as styles from "./SampleFormCreator.module.scss";
import { FieldTitle, FieldType } from "../../../utils/forms.helper";
import { SampleProps } from "../../../api/sampleList";
import Icons, { IconType } from "../../Icons/Icons";

type FormData = {
    sampleName: string;
    supplier: string;
    description: string;
    placement: string;
};

export interface SampleFormCreatorProps {
    onPreviousStep: any;
    sampleDetails: SampleProps;
}

const SampleFormCreator: React.FC<SampleFormCreatorProps> = ({ onPreviousStep, sampleDetails }) => {
    const { handleSubmit, register, errors, setValue } = useForm<FormData>();
    const { userName } = useSelector(getAuthorizationState);
    const dispatch = useDispatch();
    const handleDeleteSample = () => dispatch(deleteSample.request({ id: sampleDetails.id }));
    const onSubmitSampleForm = () =>
        handleSubmit((values: any) => {
            const reformattedValues = {
                ...values,
                userName: userName,
                id: sampleDetails.id ? sampleDetails.id : undefined,
            };

            sampleDetails.id
                ? dispatch(editSample.request(reformattedValues as any))
                : dispatch(addNewSample.request(reformattedValues as any));

            if (onPreviousStep) {
                onPreviousStep();
            }
        });

    React.useEffect(() => {
        if (sampleDetails) {
            const initialValuesMap = Object.entries(sampleDetails);
            initialValuesMap.map((item) => {
                return setValue(item[0], item[1]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="container center-xs ">
            <form onSubmit={onSubmitSampleForm()} autoComplete="off" id="sampleForm">
                <div className="flex">
                    <div className={styles.sampleInput}>
                        <span className={styles.sampleLabel}>{frontMessages.sample.form.sampleName}</span>
                        <Input
                            name={FieldTitle.SAMPLE_NAME}
                            required={true}
                            isRecipeInput={true}
                            label={frontMessages.sample.form.sampleName}
                            register={register}
                            type={FieldType.TEXT}
                            errors={errors.sampleName}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                    <div className={styles.sampleInput}>
                        <span className={styles.sampleLabel}>{frontMessages.sample.form.supplier}</span>
                        <Input
                            name={FieldTitle.SUPPLIER}
                            required={true}
                            isRecipeInput={true}
                            label={frontMessages.sample.form.supplier}
                            register={register}
                            type={FieldType.TEXT}
                            errors={errors.supplier}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                    <div className={styles.sampleInput}>
                        <span className={styles.sampleLabel}>{frontMessages.sample.form.placement}</span>
                        <Input
                            name={FieldTitle.PLACEMENT}
                            required={true}
                            isRecipeInput={true}
                            label={frontMessages.sample.form.placement}
                            register={register}
                            type={FieldType.TEXT}
                            errors={errors.placement}
                            errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                        />
                    </div>
                </div>
                <div className={styles.sampleTextArea}>
                    <span className={styles.sampleLabel}>{frontMessages.sample.form.description}</span>
                    <Input
                        name={FieldTitle.DESCRIPTION}
                        required={true}
                        isRecipeInput={true}
                        label={frontMessages.sample.form.description}
                        register={register}
                        type={FieldType.TEXT_AREA}
                        errors={errors.description}
                        errorMessage={frontMessages.addRecipe.form.recipeErrorRequired}
                    />
                </div>
                <div className={styles.sampleButtons}>
                    {sampleDetails.id ? (
                        <>
                            <button type="submit" className="btn btn--default btn--green">
                                <span>{frontMessages.button.editSample}</span>
                                <Icons iconType={IconType.EDIT} styles={"icon-button"} />
                            </button>
                            <button type="button" className="btn btn--default btn--red" onClick={handleDeleteSample}>
                                <span>{frontMessages.button.delete}</span>
                                <Icons iconType={IconType.TRASH} styles={"icon-button"} />
                            </button>
                        </>
                    ) : (
                        <button type="submit" className="btn btn--default btn--green">
                            <span>{frontMessages.button.addSample}</span>
                            <Icons iconType={IconType.PLUS} styles={"icon-button"} />
                        </button>
                    )}
                    <button type="button" className="btn btn--black" onClick={onPreviousStep}>
                        <span>{frontMessages.button.backToList}</span>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SampleFormCreator;
