import React from "react";
import { useSelector } from "react-redux";
import { getSampleListState } from "../../../store/sampleList/sampleList.selector";
import * as styles from "./SampleList.module.scss";
import { useSearcher } from "../../../hooks/useSearcher";
import { usePagination } from "../../../hooks/usePagination";
import Icons, { IconType } from "../../Icons/Icons";
import { filterSamples, sortByName } from "../../../services/filter.services";
import { useSorting } from "../../../hooks/useSorting";
import { useSelect } from "../../../hooks/useSelect";
import { SampleProps, samplesListSelect } from "../../../api/sampleList";
import { useDateFilter } from "../../../hooks/useDateFilter";
import HeaderTitleWithIcon from "../../HeaderTitleWithIcon/HeaderTitleWithIcon";

interface SampleListProps {
    handleSampleOnClick(details: SampleProps): void;
    onNextStep: any;
}

const SampleList: React.FC<SampleListProps> = ({ handleSampleOnClick, onNextStep }) => {
    const { sampleList } = useSelector(getSampleListState);
    const { searcher, searchInput } = useSearcher();
    const { paginationComponent, offset, PER_PAGE } = usePagination(sampleList.length);
    const { handleSortBy, sortBy } = useSorting(true);
    const { selectedValue, selectComponent } = useSelect(samplesListSelect);
    const { searchEndDateComponent, searchStartDateComponent, searchStartDate, searchEndDate } = useDateFilter();

    return (
        <div className={styles.samples}>
            <div className={styles.samplesTitle}>
                <HeaderTitleWithIcon title={"PRÓBY"} iconType={IconType.INGREDIENT} />
                <div className={styles.samplesTitle}>
                    <span>Prób: </span>
                    <span className="bold">{sampleList.length}</span>
                </div>
                <div className={styles.ingredientsSearch}>{searcher}</div>
                <div className={styles.ingredientsSearch}>{selectComponent}</div>
                <>
                    <div className={styles.samplesTitleDate}>{searchStartDateComponent}</div>
                    <div className={styles.samplesTitleDate}>{searchEndDateComponent}</div>
                </>
            </div>
            <div className={styles.samplesHeader}>
                <span className={styles.samplesLp}>lp.</span>
                <span className={styles.samplesName} onClick={handleSortBy}>
                    Nazwa
                    <Icons iconType={IconType.SORT} styles={styles.samplesNameIcon} />
                </span>
                <span className={styles.samplesSupplier}> Dostawca </span>
                <span className={styles.samplesPlacement}> Miejsce </span>
                <span className={styles.samplesDate}> Data </span>
                <span className={styles.samplesUser}> Użytkownik </span>
                <span className={styles.samplesDescription}> Opis </span>
            </div>
            <ul>
                {sampleList
                    .filter((item) => filterSamples(item, searchInput, selectedValue, searchStartDate, searchEndDate))
                    .sort((first, second) => sortByName(first.sampleName, second.sampleName, sortBy))
                    .slice(offset, offset + PER_PAGE)
                    .map((item, index) => {
                        return (
                            <li
                                key={item.id}
                                className={styles.samplesListItemWrapper}
                                onClick={() => {
                                    handleSampleOnClick(item);
                                    onNextStep();
                                }}>
                                <span className={styles.samplesLp}>{index + offset + 1 + ". "}</span>
                                <span className={styles.samplesName}>{item.sampleName}</span>
                                <span className={styles.samplesSupplier}>{item.supplier}</span>
                                <span className={styles.samplesPlacement}>{item.placement}</span>
                                <span className={styles.samplesDate}>{item.createdAt.slice(0, 10)}</span>
                                <span className={styles.samplesUser}>{item.userName}</span>
                                <span className={styles.samplesDescription}>{item.description}</span>
                            </li>
                        );
                    })}
            </ul>
            <div className={styles.samplesPagination}>{paginationComponent}</div>
        </div>
    );
};

export default SampleList;
