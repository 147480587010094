import React from "react";
import * as styles from "./RecipeListHeader.module.scss";
import ListHeaderTitle from "../../ListHeaderTitle/ListHeaderTitle";
import { RecipesProps } from "../../../api/recipesList";
import { RecipeSource } from "../../RecipeView/RecipeView";

interface RecipeListHeaderProps {
    recipesListState: RecipesProps[];
    recipeSource: RecipeSource;
    searcher: JSX.Element;
    selectComponent: JSX.Element;
    searchEndDateComponent: JSX.Element;
    searchStartDateComponent: JSX.Element;
    isHistory: boolean;
}

const RecipeListHeader: React.FC<RecipeListHeaderProps> = ({
    recipesListState,
    recipeSource,
    searcher,
    selectComponent,
    searchStartDateComponent,
    searchEndDateComponent,
    isHistory,
}) => {
    return (
        <div className={styles.header}>
            <div className={styles.headerTitle}>
                <div>
                    <ListHeaderTitle recipeSource={recipeSource} />
                </div>
                <div className={styles.headerCount}>
                    <span>Receptur: </span>
                    <span className="bold">{recipesListState.length}</span>
                </div>
            </div>
            <div className={styles.headerSearchBar}>
                <div className={styles.headerSearchBarInputField}>{searcher}</div>
                <div className={styles.headerSearchBarSelect}>{selectComponent}</div>
                {isHistory && (
                    <>
                        <div className={styles.headerTitle}>{searchStartDateComponent}</div>
                        <div className={styles.headerTitle}>{searchEndDateComponent}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default RecipeListHeader;
