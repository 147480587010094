import React from "react";
import * as styles from "../HeaderComponent.module.scss";
import recipeLogo from "../../../assets/images/recipeLogo.svg";
import NavLink from "../../NavLink/NavLink";
import ThemeSwitcher from "../../ThemeSwitcher/ThemeSwitcher";
import Icons, { IconType } from "../../Icons/Icons";
import { MenuItemProps } from "../HeaderNavHelpers";

export interface DesktopHeaderProps {
    menu: MenuItemProps[];
    userName: string;
    handleLogout(): void;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({ menu, userName, handleLogout }) => {
    return (
        <div className="container center-xs">
            <div className={styles.header}>
                <div className={styles.headerWrapper}>
                    <div className={styles.headerLogo}>
                        <img className={styles.headerLogoContent} src={recipeLogo} alt="logo" />
                    </div>
                    {menu.map((item, index) => {
                        return (
                            <div key={index} className={styles.headerItem}>
                                <span>{item?.menuItemName}</span>
                                <ul className={styles.headerList}>
                                    {item?.nested?.map((nestedItem, nestedIndex) => {
                                        return (
                                            <div className={styles.headerSubItem} key={nestedIndex}>
                                                <NavLink linkTo={nestedItem.linkTo}>{nestedItem.menuItemName}</NavLink>
                                            </div>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.headerUser}>
                    <div className={styles.headerUserIcon}>
                        <ThemeSwitcher />
                        <Icons iconType={IconType.USER} styles={styles.headerUserIconContent} />
                        <span className={styles.headerUserName}>{userName}</span>
                    </div>
                    <button onClick={handleLogout} className="btn--default btn--width-medium">
                        WYLOGUJ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DesktopHeader;
